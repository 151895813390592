<template>
<div class="container">
  <ScrollNotice />
  <div class="head clearFix">
    <div class="content">
      <div class="tip">最新</div>
      <div class="title">
        <div class="post">{{info.typework}}</div>
        <div class="price">{{info.price}}</div>
      </div>
      <div class="require">
        {{info.city}}
        <el-divider direction="vertical" />
        {{info.experience}}
        <el-divider direction="vertical" />
        {{info.minEducation}}
      </div>
      <div class="concat">
        <el-button>立即沟通</el-button>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="name_avatar">
      <el-image :src="info.upic"/>
      <div class="name_wrap">
        <div class="name">{{info.uname}}</div>
        <div class="post">求职：&nbsp;{{info.typework}}</div>
      </div>
    </div>
    <div class="title">
      个人介绍
      <el-divider />
    </div>
    <div class="form">
      <el-form ref="form" :model="info" label-width="auto">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名：">
<!--              <el-input v-model="info.name"></el-input>-->
              {{info.name}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别：">
              {{info.sex}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄：">
              {{info.age}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="婚姻状况：">
              {{info.marriage}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身高：">
              {{info.height}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="体重：">
              {{info.weight}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学历：">
              {{info.education}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职业：">
              {{info.typework}}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话：">
              {{info.tel}}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="微信：">
              {{info.weixin}}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱：">
              {{info.email}}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="家庭住址：">
              {{info.address}}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="个人介绍：">
              {{info.info}}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</div>
</template>

<script>
  import axios from "axios"; 
  import ScrollNotice from '@/components/ScrollNotice'
  export default {
    name: 'Seeker',
    components: { ScrollNotice },
    data() {
      return {
        info: {
          typework: '骨科医生',
          price: '面议',
          city: '济南',
          experience: '1-3年',
          education: '研究生',
          name: '王大哥',
          sex: '男',
          age: '30岁',
          marriage: '未婚',
          height: '187cm  ',
          weight: '123kg',
          //minEducation: '本科',
          tel: '19988888888',
          weixin: '19988888888',
          email: '19988888888',
          address: '山东省济南市槐荫区XXXXXX',
          intro: '\n' +
            '•自信、诚信、勤劳的我遇事觉着冷静，能吃苦耐劳,善于思考，有很好的团队合作精神\n' +
            '•善于交际，能很轻松的与同学、同事共事同处\n' +
            '•有一颗善良的心',
          avatar: require('@/assets/avatar/弘淑.jpg')
        }
      }
    },
	created(){
		
		var query=this.$route.query;
		
		//this.id = query.id; 
		//alert(query.id);
		
		const params = new URLSearchParams();
		params.append('id', query.id); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getResumeListInfo',params)
		.then((response) => {  
			console.log(response.data.data);
			this.info = response.data.data;  
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		   
	},
  }
</script>

<style scoped lang="scss">
.container{
  .head{
    height: 306px;
    background-color: #353849;
    color: white;
    .content{
      width: 1200px;
      height: 216px;
      margin: auto;
      padding: 45px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tip{}
      .title{
        font-size: 32px;
        display: flex;
        flex-direction: row;
        .post{
          margin-right: 116px;
        }
        .price{
          color: $red;
          font-size: 28px;
        }
      }
      .require{
        font-size: 14px;
        .el-divider--vertical{
          height: 2px;
          width: 2px;
          vertical-align: baseline;
        }
      }
      .concat{
        .el-button{
          width: 125px;
          height: 45px;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .body{
    width: 1200px;
    height: 1000px;
    margin: 40px auto 140px;
    background-color: #fff;
    border-radius: 3px;
    padding: 26px 30px;
    .name_avatar{
      display: flex;
      flex-direction: row;
      margin-bottom: 36px;
      .el-image{
        width: 64px;
        height: 64px;
        border-radius: 50%;
        margin-right: 20px;
        border: 1px solid #a0a0a0;
      }
      .name_wrap{
        height: 40px;
        padding: 12px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name{
          font-size: 14px;
        }
        .post{
          font-size: 12px;
          color: #888;
        }
      }
    }
    .title{
      .el-divider{
        width: 20px;
        height: 2px;
        background-color: $green;
        margin: 11px 0;
      }
    }
    .form{
      width: 800px;
      margin-top: 60px;
    }
  }
}
/deep/ .el-form-item__label-wrap .el-form-item__label{
  color: #9c9c9c;
}
/deep/ .el-form-item__content{
  padding-left: 14px!important;
}
</style>
